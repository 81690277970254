import { FC } from 'react';

export const SavedIcon: FC = (): JSX.Element => {
  return (
    <svg
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.53372 3.03894L9.53391 3.03914L11.2919 4.79564L11.9987 5.50184L12.7055 4.79564L14.4635 3.03914L14.4698 3.03285L14.476 3.02644C14.9372 2.54889 15.489 2.16798 16.099 1.90593C16.709 1.64389 17.3651 1.50596 18.029 1.50019C18.6929 1.49442 19.3513 1.62093 19.9658 1.87233C20.5803 2.12374 21.1385 2.495 21.608 2.96446C22.0774 3.43393 22.4487 3.99219 22.7001 4.60667C22.9515 5.22115 23.078 5.87955 23.0723 6.54345C23.0665 7.20734 22.9286 7.86344 22.6665 8.47346C22.4045 9.08348 22.0236 9.63521 21.546 10.0964L21.5397 10.1025L21.5336 10.1087L11.9987 19.6449L2.46393 10.1087C2.46391 10.1087 2.4639 10.1087 2.46388 10.1087C1.52656 9.17104 1 7.89953 1 6.57373C1 5.24801 1.5265 3.97656 2.46372 3.03894C2.46375 3.03891 2.46379 3.03887 2.46383 3.03884C2.46386 3.0388 2.4639 3.03876 2.46393 3.03873C3.40155 2.10151 4.673 1.57501 5.99872 1.57501C7.32454 1.57501 8.59608 2.10159 9.53372 3.03894Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="2"
      />
    </svg>
  );
};

export const PersonsIcon: FC = (): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55871 10.6839 8.79565 11 8 11C7.20435 11 6.44129 10.6839 5.87868 10.1213C5.31607 9.55871 5 8.79565 5 8C5 7.20435 5.31607 6.44129 5.87868 5.87868C6.44129 5.31607 7.20435 5 8 5C8.79565 5 9.55871 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8ZM19 8C19 8.39397 18.9224 8.78407 18.7716 9.14805C18.6209 9.51203 18.3999 9.84274 18.1213 10.1213C17.8427 10.3999 17.512 10.6209 17.1481 10.7716C16.7841 10.9224 16.394 11 16 11C15.606 11 15.2159 10.9224 14.8519 10.7716C14.488 10.6209 14.1573 10.3999 13.8787 10.1213C13.6001 9.84274 13.3791 9.51203 13.2284 9.14805C13.0776 8.78407 13 8.39397 13 8C13 7.20435 13.3161 6.44129 13.8787 5.87868C14.4413 5.31607 15.2044 5 16 5C16.7956 5 17.5587 5.31607 18.1213 5.87868C18.6839 6.44129 19 7.20435 19 8ZM14.93 19C14.976 18.673 15 18.34 15 18C15.0023 16.4289 14.4737 14.903 13.5 13.67C14.2601 13.2312 15.1223 13.0001 16 13.0001C16.8776 13.0001 17.7399 13.2311 18.4999 13.67C19.26 14.1088 19.8912 14.74 20.3301 15.5C20.7689 16.2601 21 17.1223 21 18V19H14.93ZM8 13C9.32608 13 10.5979 13.5268 11.5355 14.4645C12.4732 15.4021 13 16.6739 13 18V19H3V18C3 16.6739 3.52678 15.4021 4.46447 14.4645C5.40215 13.5268 6.67392 13 8 13Z"
        fill="#7D7F7F"
      />
    </svg>
  );
};

export const CheckedCircleIcon: FC = (): JSX.Element => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5 28.8333C18.3014 28.8333 21.9471 27.3232 24.6352 24.6352C27.3232 21.9472 28.8333 18.3014 28.8333 14.5C28.8333 10.6986 27.3232 7.05281 24.6352 4.36479C21.9471 1.67677 18.3014 0.166656 14.5 0.166656C10.6985 0.166656 7.05278 1.67677 4.36476 4.36479C1.67674 7.05281 0.166626 10.6986 0.166626 14.5C0.166626 18.3014 1.67674 21.9472 4.36476 24.6352C7.05278 27.3232 10.6985 28.8333 14.5 28.8333V28.8333ZM21.1417 12.1834C21.468 11.8455 21.6486 11.3929 21.6445 10.9231C21.6405 10.4533 21.452 10.004 21.1198 9.67177C20.7877 9.33958 20.3383 9.15116 19.8685 9.14707C19.3987 9.14299 18.9462 9.32358 18.6083 9.64995L12.7083 15.5499L10.3917 13.2333C10.0538 12.9069 9.60118 12.7263 9.13141 12.7304C8.66164 12.7345 8.21227 12.9229 7.88008 13.2551C7.54789 13.5873 7.35946 14.0367 7.35538 14.5064C7.35129 14.9762 7.53188 15.4288 7.85825 15.7667L11.4416 19.35C11.7776 19.6859 12.2332 19.8746 12.7083 19.8746C13.1834 19.8746 13.639 19.6859 13.975 19.35L21.1417 12.1834V12.1834Z"
        fill="#45CE93"
      />
    </svg>
  );
};

export const EditIcon: FC = (): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.586 5.58598C15.7705 5.39496 15.9912 5.24259 16.2352 5.13778C16.4792 5.03296 16.7416 4.97779 17.0072 4.97548C17.2728 4.97317 17.5361 5.02377 17.7819 5.12434C18.0277 5.2249 18.251 5.3734 18.4388 5.56119C18.6266 5.74897 18.7751 5.97228 18.8756 6.21807C18.9762 6.46386 19.0268 6.72722 19.0245 6.99278C19.0222 7.25834 18.967 7.52078 18.8622 7.76479C18.7574 8.0088 18.605 8.22949 18.414 8.41398L17.621 9.20698L14.793 6.37898L15.586 5.58598ZM13.379 7.79298L5 16.172V19H7.828L16.208 10.621L13.378 7.79298H13.379Z"
        fill="#2F80ED"
      />
    </svg>
  );
};

export const YELLOW_FLAG = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 3C0 2.20435 0.316071 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0H13C13.1857 0 13.3678 0.0517147 13.5257 0.149349C13.6837 0.246984 13.8114 0.386681 13.8944 0.552786C13.9775 0.718892 14.0126 0.904844 13.996 1.08981C13.9793 1.27477 13.9114 1.45143 13.8 1.6L11.25 5L13.8 8.4C13.9114 8.54857 13.9793 8.72523 13.996 8.91019C14.0126 9.09516 13.9775 9.28111 13.8944 9.44721C13.8114 9.61332 13.6837 9.75302 13.5257 9.85065C13.3678 9.94829 13.1857 10 13 10H3C2.73478 10 2.48043 10.1054 2.29289 10.2929C2.10536 10.4804 2 10.7348 2 11V14C2 14.2652 1.89464 14.5196 1.70711 14.7071C1.51957 14.8946 1.26522 15 1 15C0.734784 15 0.48043 14.8946 0.292893 14.7071C0.105357 14.5196 0 14.2652 0 14V3Z"
        fill="#FFCD4B"
      />
    </svg>
  );
};

export const ExpandIcon: FC = (): JSX.Element => {
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      width="19px"
      height="19px"
    >
      <path
        d="M21 8.59V4c0-.55-.45-1-1-1h-4.59c-.89 0-1.34 1.08-.71 1.71l1.59 1.59-10 10-1.59-1.59c-.62-.63-1.7-.19-1.7.7V20c0 .55.45 1 1 1h4.59c.89 0 1.34-1.08.71-1.71L7.71 17.7l10-10 1.59 1.59c.62.63 1.7.19 1.7-.7"
        fill="#A9A9A9"
      ></path>
    </svg>
  );
};

export const MinimizeIcon: FC = (): JSX.Element => {
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      width="19px"
      height="19px"
    >
      <path
        d="M21.29 4.12 16.7 8.71l1.59 1.59c.63.63.18 1.71-.71 1.71H13c-.55 0-1-.45-1-1v-4.6c0-.89 1.08-1.34 1.71-.71l1.59 1.59 4.59-4.59c.39-.39 1.02-.39 1.41 0 .38.4.38 1.03-.01 1.42M4.12 21.29l4.59-4.59 1.59 1.59c.63.63 1.71.18 1.71-.71V13c0-.55-.45-1-1-1h-4.6c-.89 0-1.34 1.08-.71 1.71l1.59 1.59-4.59 4.59c-.39.39-.39 1.02 0 1.41.4.38 1.03.38 1.42-.01"
        fill="#A9A9A9"
      ></path>
    </svg>
  );
};
