import { createStyles, makeStyles, Theme } from '@material-ui/core';
import fonts from '../common/fonts';
import colors from '../common/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    positionRelative: {
      position: 'relative',
    },

    chatbotToggleButton: {
      padding: 0,
      zIndex: 9999,
      right: '80px',
      bottom: '20px',
      position: 'fixed',
    },

    chatbotFullScreenOverlay: {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      position: 'fixed',
      zIndex: 99999999,
      backgroundColor: colors.RGBA_BLACK_OPACITY,
      transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
    },

    closeButtonChatbot: {
      top: 24,
      right: 15,
      width: 30,
      height: 30,
      position: 'absolute',
      color: colors.GRAY_TWENTYTWO,
    },

    minMaxButtonChatbot: {
      top: 24,
      right: 45,
      width: 30,
      padding: 5,
      height: 30,
      position: 'absolute',
    },

    accountLockedAlert: {
      display: 'flex',
      borderRadius: 15,
      marginBottom: 45,
      background: '#FFEFEB',
      alignItems: 'flex-start',
      padding: '20px 16px 15px',

      '& img': {
        marginRight: 10,
      },

      "& [class*='MuiTypography-h4']": {
        fontSize: 14,
        fontWeight: 600,
        color: '#272929',
        padding: 0,
        letterSpacing: '-0.084px',
        fontFamily: fonts.POPPINS_FONT,
      },

      "& [class*='MuiTypography-body1']": {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '24px',
        margin: '4px 0px 0px',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.084px',
        color: '#7D7F7F',
        textAlign: 'left',

        '& a': {
          color: '#7D7F7F',
          borderBottom: '1px solid #7D7F7F',
        },
      },

      "& [class*='MuiIconButton-root']": {
        padding: 6,
      },
    },

    alertInfoCard: {
      background: '#EBF3FE',
      borderRadius: 15,
      padding: '23px 20px',
      boxShadow: 'none',
      marginBottom: 43,

      '@media only screen and (max-width: 767px)': {
        padding: 15,
        margin: '15px 0px',
      },

      '& img': {
        marginRight: 30,
        position: 'relative',
        bottom: 4,
      },

      '& .MuiTypography-h5, .MuiButton-root': {
        fontFamily: 'Poppins',
        fontSize: 14,
        letterSpacing: '-0.006em',
      },

      '& .MuiTypography-h5': {
        fontWeight: 600,
        color: '#272929',
      },

      '& .MuiTypography-body1': {
        fontSize: 14,
        color: '#62636B',
        margin: '4px 0px 12px',
        letterSpacing: '-0.006em',
        fontFamily: fonts.INTER,
      },

      '& .MuiButton-root': {
        background: '#2F80ED',
        borderRadius: 12,
        color: '#FFFFFF',
        padding: '8px 15px',
        textTransform: 'capitalize',
      },
    },

    card: {
      background: '#FFF8E4',
      borderRadius: 15,
      padding: '23px 20px',
      boxShadow: 'none',
      marginBottom: 40,
      marginTop: 30,

      '@media only screen and (max-width: 767px)': {
        padding: '15px',
        margin: 15,
      },

      '& img': {
        marginRight: 20,
        position: 'relative',
        bottom: 4,
      },

      '& .MuiTypography-h5, .MuiTypography-body1, .MuiButton-root': {
        fontFamily: 'Poppins',
        fontSize: 14,
        letterSpacing: '-0.006em',
      },

      '& .MuiTypography-h5': {
        fontWeight: 600,
        color: '#272929',
      },

      '& .MuiTypography-body1': {
        fontWeight: 'normal',
        color: '#62636B',
        margin: '4px 0px 12px',
      },

      '& .MuiButton-root': {
        background: '#2F80ED',
        borderRadius: 6,
        color: '#FFFFFF',
        padding: '8px 15px',
        textTransform: 'capitalize',
      },
      '& .alert-description': {
        fontFamily: fonts.INTER,
      },
    },

    infoCard: {
      background: '#EBF3FE',

      '& .MuiTypography-body1': {
        color: '#525454',
        letterSpacing: '-0.006em',
      },
    },
    errorCard: {
      background: '#FFEFEB !important',
    },
    button: {
      '&.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.26)',
        boxShadow: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },
    },

    linkOnlineMeeting: {
      '& .MuiDialog-paper': {
        maxWidth: 689,
        borderRadius: 25,
        border: '1px solid #D4D4D4',
        padding: '44px 44px 53px 50px',
        boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

        [theme.breakpoints.down('xs')]: {
          margin: 10,
          padding: 20,
        },
      },

      '& .dialogHeading': {
        fontSize: 24,
        fontWeight: 600,
        color: '#272929',
        letterSpacing: '-0.019em',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .MuiTypography-h5': {
        fontFamily: fonts.POPPINS_FONT,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '32px',
        alignItems: 'center',
        letterSpacing: '-0.019em',
        color: '#272929',
      },

      '& .myMeetings': {
        fontSize: 18,
        fontWeight: 600,
        marginBottom: 31,
        color: '#A9A9A9',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.014em',
      },

      '& .MuiTableCell-head': {
        border: 0,
        padding: 0,
      },
    },
  })
);
