import gql from 'graphql-tag';
import client from '../apollo';
import { LIST_GRAD_YEARS } from '../graphql/queries/StudentNotes';
import { ListGradYearAndPremium_listGradYearAndPremium } from '../graphql/queries/StudentNotes/__generated__/ListGradYearAndPremium';
import { StudentDetailQuery_Student_allStudentMentors } from '../pages/main/students/__generated__/StudentDetailQuery';

export const TOKEN = 'token';
export const APP_VERSION = 'app_version';

export const SVG_PATH1 =
  'M10.0723 5.41211C9.22525 5.41211 8.5182 5.70799 7.95231 6.18858C7.38643 6.66917 7.05526 7.05917 6.95996 8.23564H8.68761C8.72526 7.6474 8.86584 7.51152 9.10878 7.28799C9.35173 7.06446 9.65349 6.92446 10.0153 6.92446C10.377 6.92446 10.6717 7.02682 10.9 7.26034C11.1288 7.49329 11.2429 7.77917 11.2429 8.13093C11.2429 8.4827 11.1329 8.77446 10.9147 9.0127L9.87231 10.0533C9.56761 10.358 9.50408 10.6027 9.41349 10.788C9.3229 10.9733 9.41173 11.2551 9.41173 11.6356V12.3533H10.5882V11.8639C10.5882 11.4839 10.7582 11.1886 10.93 10.9792C10.9958 10.9033 11.1258 10.8103 11.2364 10.7009C11.3458 10.5909 11.5 10.4627 11.657 10.3151C11.8141 10.168 11.9511 10.0462 12.0464 9.95093C12.1411 9.85623 12.2841 9.69387 12.4653 9.46564C12.7788 9.08505 12.9388 8.60917 12.9388 8.03799C12.9388 7.20976 12.6741 6.56564 12.1411 6.10329C11.607 5.6427 10.9194 5.41211 10.0723 5.41211Z';
export const SVG_PATH2 =
  'M9.96491 13.0254C9.66491 13.0254 9.40844 13.1307 9.19373 13.3395C8.97962 13.5489 8.87256 13.7983 8.87256 14.0889C8.87256 14.3789 8.98197 14.6266 9.20079 14.8313C9.41962 15.036 9.67903 15.1377 9.97844 15.1377C10.2784 15.1377 10.5355 15.033 10.7496 14.8242C10.9631 14.6148 11.0708 14.3648 11.0708 14.0748C11.0708 13.7848 10.9608 13.5372 10.742 13.3319C10.5243 13.1272 10.2649 13.0254 9.96491 13.0254Z';
export const SVG_PATH3 =
  'M10 0C4.47706 0 0 4.47706 0 10C0 15.5229 4.47706 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47706 15.5229 0 10 0ZM10 18.2353C5.45177 18.2353 1.76471 14.5482 1.76471 10C1.76471 5.45177 5.45177 1.76471 10 1.76471C14.5482 1.76471 18.2353 5.45177 18.2353 10C18.2353 14.5482 14.5482 18.2353 10 18.2353Z';

export const CAP_ENROLLEE = 'CAP Enrollee';
export const CURRENT_YEAR = new Date().getFullYear();
export const HOURS_ARRAY = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

export const MINUTES_ARRAY = [
  '00',
  '05',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
];

export const LINK_TO_DOCS_INCENTIVE_BONUS_DETAILS =
  'https://docs.google.com/document/d/10c7_EjCBLjOPKXOT83S12TphVObijeaZryHDWeyWGyM/edit?usp=sharing';

export const INCENTIVE_BREAKDOWN_DATA = [
  {
    heading: 'College Decisions',
    description:
      'Decisions must be collected on all the corresponding number of applications that are entitled to assistance with each package. These should be the applications whose essays received your assistance.',
  },

  {
    heading: 'Positive Reviews',
    subHeading: '(Up to 7)',
    description:
      'Primary Advisors on packages are eligible for bonuses on each Promoter NPS submission that is submitted by the family. Advisors can also receive a bonus if a client leaves a 5/5 public review on our Trustpilot page, which counts for two NPS Promoter bonuses. Primary Advisors can receive bonuses for up to five Promoter NPS submissions & one 5/5 Trustpilot submission for a total of seven bonuses.',
  },

  {
    heading: 'Exit Survey',
    description:
      'Both Advisors & AOs are eligible for bonuses for a client submitting an Exit Survey, as each has their own distinct rating question. In order to be eligible for a bonus, a client must submit an Exit Survey and include an Advisor Promoter rating or AO Promoter rating.',
  },
];

export const convertToUSD = (amount: number): string => {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const INCENTIVE_STUDENT_PACKAGE_DATA = [
  {
    packageName: 'Premium',
    decisions: '$25',
    reviews: '$5 each',
    exitSurvey: '$25',
    advisorShare: '$85',
    aoShare: 'N/A',
    packageTotal: '$85',
  },

  {
    packageName: 'Elite',
    decisions: '$50',
    reviews: '$10 each',
    exitSurvey: '$50',
    advisorShare: '$170',
    aoShare: 'N/A',
    packageTotal: '$170',
  },

  {
    packageName: 'Platinum',
    decisions: '$75',
    reviews: '$15 each',
    exitSurvey: '$125',
    advisorShare: '$255',
    aoShare: '$50',
    packageTotal: '$305',
  },

  {
    packageName: 'Ivy Plus',
    decisions: '$100',
    reviews: '$20 each',
    exitSurvey: '$175',
    advisorShare: '$345',
    aoShare: '$100',
    packageTotal: '$445',
  },

  {
    packageName: 'Diamond',
    decisions: '$175',
    reviews: '$30 each',
    exitSurvey: '$200',
    advisorShare: 'N/A',
    aoShare: '$560',
    packageTotal: '$560',
  },
];

export const PACKAGE_LIST = [
  'Ivy Plus',
  'Platinum',
  'Elite',
  'Premium',
  'CWS',
  'Diamond',
];

export const roundNumber = (value: number, precision: number) => {
  const hasDecimal = value.toString().indexOf('.');
  if (hasDecimal > 0) {
    let val = value.toString().slice(0, hasDecimal + 1 + precision);
    return parseFloat(val);
  }
  return value;
};

export const fetchGradYear = async (MentorId: number | null) => {
  const valueLists = await client.query({
    query: LIST_GRAD_YEARS,
    variables: { MentorId },
  });
  let gradRequiredLists: string[] = valueLists.data.listGradYearAndPremium.map(
    (listOfGradYear: ListGradYearAndPremium_listGradYearAndPremium) =>
      listOfGradYear?.student?.highSchoolYear
  );
  gradRequiredLists = gradRequiredLists.filter(
    (item: string, index: number, array: string[]) => {
      return array.indexOf(item) === index && item !== null;
    }
  );
  let premiumRequiredLists: string[] =
    valueLists.data.listGradYearAndPremium.map(
      (listOfPremiumPackage: ListGradYearAndPremium_listGradYearAndPremium) =>
        listOfPremiumPackage?.student?.premiumPackageType
    );
  premiumRequiredLists = premiumRequiredLists.map((value) => {
    return PACKAGE_LIST.includes(value) ? value : 'Custom';
  });
  premiumRequiredLists = premiumRequiredLists.filter(
    (item: string, index: number, array: string[]) => {
      return array.indexOf(item) === index && item !== null;
    }
  );

  gradRequiredLists.sort().reverse(); // to sort the grad year list in desc order

  return {
    GRAD_YEARS_LIST: ['Grad Year', ...gradRequiredLists],
    PACKAGE_LIST: ['Package Type', ...premiumRequiredLists],
  };
};

export const ALL_MEETING_CANCEL_REASONS = [
  {
    label: 'Student conflict ',
    value: 'student-conflict',
  },
  {
    label: 'Advisor conflict',
    value: 'advisor-conflict',
  },
  {
    label: 'Student emergency',
    value: 'student-emergency',
  },
  {
    label: 'Advisor emergency',
    value: 'advisor-emergency',
  },
  {
    label: 'Student not prepared',
    value: 'not-prepared',
  },
  {
    label: 'Meeting no longer needed',
    value: 'no-needed',
  },
  {
    label: 'Student No-Show',
    value: 'no-show',
  },
  {
    label: 'Conflicting appointment',
    value: 'conflicting-appointment',
  },
  {
    label: 'Other',
    value: 'other',
  },
  {
    label: 'Student Called To Cancel',
    value: 'called-to-cancel',
  },
  {
    label: 'Rescheduled',
    value: 'rescheduled',
  },
];
export const MEETING_CANCEL_REASONS = [
  {
    label: 'Student conflict ',
    value: 'student-conflict',
  },
  {
    label: 'Advisor conflict',
    value: 'advisor-conflict',
  },
  {
    label: 'Student emergency',
    value: 'student-emergency',
  },
  {
    label: 'Advisor emergency',
    value: 'advisor-emergency',
  },
  {
    label: 'Student not prepared',
    value: 'not-prepared',
  },
  {
    label: 'Meeting no longer needed',
    value: 'no-needed',
  },
  {
    label: 'Student No-Show',
    value: 'no-show',
  },
  {
    label: 'Other',
    value: 'other',
  },
];
export const NOTES_CATEGORIES = [
  { name: 'Sales Note', value: 'sales_notes' },
  { name: 'Customer Service Note', value: 'customer_service_note' },
  {
    name: 'Cancel Request – 14 Day Cancel – Advising / Service Related',
    value: 'cancel_request_14_day_cancel_advising_service_related',
  },
  {
    name: 'Cancel Request – 14 Day Cancel - Other',
    value: 'cancel_request_14_day_cancel_other',
  },
  {
    name: 'Cancel Request – Service Breakdown',
    value: 'cancel_request_service_breakdown',
  },
  {
    name: 'Cancel Request – Change of Heart',
    value: 'cancel_request_change_of_heart',
  },
  {
    name: 'Cancel Request – Expectations Misalignment',
    value: 'cancel_request_expectations_misalignment',
  },
  {
    name: 'Cancel Request – Financial / Health Hardship',
    value: 'cancel_request_financial_health_hardship',
  },
  {
    name: 'Early Termination Fee Scheduled',
    value: 'early_termination_fee_scheduled',
  },
  {
    name: 'Advising Note',
    value: 'advising_note',
  },
  // {
  //   name: 'Time Log Note',
  //   value: 'timelog_note',
  // },
];

export const bookingInfoStatuses = {
  CANCELLED: 'cancelled',
  DELETED: 'deleted',
  UPCOMING: 'upcoming',
  COMPLETED: 'completed',
};

export const optionsDecision = [
  { value: 'Accepted', label: 'Accepted' },
  { value: 'Denied', label: 'Denied' },
  { value: 'Deferred', label: 'Deferred' },
  { value: 'Waitlisted', label: 'Waitlisted' },
  { value: 'Withdraw', label: 'Withdraw' },
  { value: 'Did not apply', label: 'Did not apply' },
];

const CREATE_USER_ACTIVITY_MUTATION = gql`
  mutation createUserActivityMutation(
    $pageName: String
    $action: String
    $type: String
    $origin: String
    $objectId: Int
  ) {
    createUserActivity(
      input: {
        pageName: $pageName
        action: $action
        type: $type
        origin: $origin
        objectId: $objectId
      }
    ) {
      success
      message
    }
  }
`;
export const StudentDetailsTabs: { [key: string]: number } = {
  info: 0,
  profile: 1,
  meetings: 2,
  applications: 3,
  opportunities: 4,
  timeLogs: 5,
  notes: 6,
};

export const UserActivityActions = {
  VISIT: 'visit',
  DEMO: 'DisplayedProductDemo',
  VIEW: 'view',
  CLICKED_APP: 'ClickedMyApplications',
  CLICKED_SCHOOL: 'ClickedExploreSchool',
  DEMO_WATCH: 'watchedCompleteProductDemo',
  SEARCH: 'search',
  DEMO_PLAY: 'autoPlayedProductDemo',
  CLICKED_WEBINAR: 'ClickedWebinar',
  CLICKED_TOURS: 'ClickedVideoTours',
  CLICKED_ESSAY: 'ClickedEssayRequirrements',
  CLICKED_ADVISOR: 'ClickedAdvisor',
  PLAY: 'play',
  CLICKED_CHATBOT: 'ClickedChatBot',
};

export const logUserActivity = async (payload: {
  pageName: string;
  action: string;
  type: string;
  origin: string | null;
  objectId?: number;
}) => {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_USER_ACTIVITY_MUTATION,
      variables: payload,
    });
    if (data.createUserActivity.success) {
    } else {
    }
  } catch (err) {
    console.log(err);
  }
};

export function isValueBetween(value: number, min: number, max: number) {
  return value >= min && value <= max;
}

export const UserActivityType = {
  WEBINAR: 'Webinar',
  COLLEGE_HUB: 'CollegeHubPages',
  SUMMER_OP: 'ExtracurricularOpportunity',
  PRODUCT_DEMO: 'ProductDemo',
  COLLEGE_SEARCH: 'CollegeSearch',
  CHAT_BOT: 'ChatBot',
};

export const sortAdvisorsWithLevel = (
  advisors: (StudentDetailQuery_Student_allStudentMentors | null)[] | undefined
) => {
  if (!advisors) return [];
  let arr = [];
  for (const adv of advisors) {
    if (adv?.mentor?.advisorLevel === 'Advisor' && !adv.isExtraMatch) {
      arr.unshift(adv);
    }
    if (adv?.mentor?.advisorLevel === 'Senior Advisor' && !adv.isExtraMatch) {
      const index = arr
        ?.map((item) => item?.mentor?.advisorLevel)
        .lastIndexOf('Advisor');
      arr.splice(index < 0 ? 0 : index + 1, 0, adv);
    }
    if (
      adv?.mentor?.advisorLevel === 'Former Admissions Officer' &&
      !adv.isExtraMatch
    ) {
      arr.push(adv);
    }
  }
  for (const adv of advisors) {
    if (adv?.isExtraMatch) {
      arr.push(adv);
    }
  }
  return arr;
};

export const associationTypeOptions = [
  {
    label: 'BSMD/Pre-Health Program',
    value: 'BSMD Program',
  },
  {
    label: 'Other Additional Advisor',
    value: 'others',
  },
];

export const topicsDiscussedInMeeting = [
  {
    label: 'AP Test Plans',
    value: 'AP Test Plans',
  },
  {
    label: 'Standardized Testing Timeline',
    value: 'Standardized Testing Timeline',
  },
  {
    label: 'Relevant Coursework for Intended Major',
    value: 'Relevant Coursework for Intended Major',
  },
  {
    label: 'College Admissions Timeline',
    value: 'College Admissions Timeline',
  },
  {
    label: 'College Visits & Fairs',
    value: 'College Visits & Fairs',
  },
  {
    label: 'Letters of Recommendation',
    value: 'Letters of Recommendation',
  },
  {
    label: 'Extracurriculars',
    value: 'Extracurriculars',
  },
  {
    label: 'Developing College List',
    value: 'Developing College List',
  },
  {
    label: 'College Applications',
    value: 'College Applications',
  },
  {
    label: 'College Essays',
    value: 'College Essays',
  },

  {
    label: 'College Interviews',
    value: 'College Interviews',
  },
  {
    label: 'Alternative Post-High School Options',
    value: 'Alternative Post-High School Options',
  },
  {
    label: 'Interest Inventories & Career Exploration',
    value: 'Interest Inventories & Career Exploration',
  },
  {
    label: 'Internships',
    value: 'Internships',
  },
  {
    label: 'Resume Development',
    value: 'Resume Development',
  },
  {
    label: 'Community Service',
    value: 'Community Service',
  },
  {
    label: 'Summer Plans',
    value: 'Summer Plans',
  },
];

export const chartLabels = {
  ESTIMATION: 'Estimation',
  MAX: 'Max',
  LAST30DAYS: 'Last 30 Days',
};
export const AVINCENT_EMAIL = 'avincent@collegeadvisor.com';

export const getStudentBonus = ({ shortName }: { shortName: String }) => {
  const bonus = INCENTIVE_STUDENT_PACKAGE_DATA.find(
    (pkg) => pkg.packageName === shortName
  );
  return bonus?.advisorShare;
};
export const MENTOR_TIMELOG_ASSOCIATION_TYPES = {
  studentApplications: 'Student Applications',
};

export const PRONOUNS_ARRAY = [
  'She/Her/Hers',
  'He/Him/His',
  'They/Them/Theirs',
];

export const VIDEO_CHAT_USAGE_FILTER = [
  'Video Chat Usage',
  '0 - 74.9%',
  '75 - 99.9%',
  '100%+',
];
export const OFFLINE_COUNT_USAGE_FILTER = [
  'Offline Usage',
  '0 - 74.9%',
  '75 - 99.9%',
  '100%+',
];
export const SALES_USER_TOKEN = 'salesUserToken';
export const SALES_USER_EMAIL = 'salesUserEmail';
export enum dfMessengerBotType {
  advisor = 'advisor',
  sales = 'sales',
}

export const allowedDfMessengerEmails = [
  'nyassin@joincollegeadvisor.com',
  'jmaloto@collegeadvisor.com',
  'avincent@collegeadvisor.com',
  'rmoore@collegeadvisor.com',
  'abisnauth@collegeadvisor.com',
  // 'amian@joincollegeadvisor.com',
  // 'anaguib@joincollegeadvisor.com',
  // 'aeguiguren@joincollegeadvisor.com',
  // 'agrant@joincollegeadvisor.com',
  // 'aabney@joincollegeadvisor.com',
  // 'cchan@joincollegeadvisor.com',
  // 'cokaneme@joincollegeadvisor.com',
  // 'cng@joincollegeadvisor.com',
  // 'ematriccino@joincollegeadvisor.com',
  // 'fdemata@joincollegeadvisor.com',
  // 'hdeo@joincollegeadvisor.com',
  // 'hcannon@joincollegeadvisor.com',
  // 'lvrooyen@joincollegeadvisor.com',
  // 'lhill@joincollegeadvisor.com',
  // 'mpham@joincollegeadvisor.com',
  // 'mlutz@joincollegeadvisor.com',
  // 'mytang@joincollegeadvisor.com',
  // 'snorton@joincollegeadvisor.com',
  // 'tlopez@joincollegeadvisor.com',
  // 'bbourgeois@joincollegeadvisor.com',
  // 'cstellmach@joincollegeadvisor.com',
  // 'cdalton@joincollegeadvisor.com',
  // 'csia@joincollegeadvisor.com',
  // 'cbancroft@joincollegeadvisor.com',
  // 'cchu@joincollegeadvisor.com',
  // 'ghall@joincollegeadvisor.com',
  // 'hsaleh@joincollegeadvisor.com',
  // 'isantiago@joincollegeadvisor.com',
  // 'jscott@joincollegeadvisor.com',
  // 'jlopez@joincollegeadvisor.com',
  // 'lzhu@joincollegeadvisor.com',
  // 'rross@joincollegeadvisor.com',
  // 'ycapellino@joincollegeadvisor.com',
  // 'nyasin@collegeadvisor.com',
];
